const mainSlider = '#mvSlider'
const thumbSlider = '#mvSliderThumb'
const mainSlides = document.getElementsByClassName('main-slide')
const thumbSlides = document.getElementsByClassName('thumb-slide')
let slideChangePermit = false

const thumbSwiper = new Swiper(thumbSlider, {
  loop: true,
  autoplay: { delay: 5e3, disableOnInteraction: false },
  // slidesPerView: 5,
  loopedSlides: mainSlides.length,
  centeredSlides: true,
  spaceBetween: 30,
  preventClicks: false,
  // slidesPerView: 4.5,
  slidesPerView: 'auto',
  allowTouchMove: false,
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
  breakpoints: {
    768: {
      loop: true,
      loopedSlides: mainSlides.length,
      autoplay: { delay: 5e3, disableOnInteraction: false },
      allowTouchMove: false,
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 10,
    },
  },
  controller: {
    control: mainSwiper,
  },
})

const mainSwiper = new Swiper(mainSlider, {
  loop: true,
  loopedSlides: mainSlides.length,
  autoplay: { delay: 5e3, disableOnInteraction: false },
  centeredSlides: true,
  pagination: { el: '.swiper-pagination', clickable: true },
  slidesPerView: 'auto',
  spaceBetween: 30,
  allowTouchMove: false,
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
  // thumbs: { swiper: e },
  // controller: {
  //   control: thumbSwiper,
  // },
  on: {
    slideChange: function () {
      const current = this.activeIndex
      thumbSwiper.slideTo(current, 300, true)
      setTimeout(() => {
        thumbSwiper.autoplay.start()
      }, 3000)
    },
  },
})

const pickupNewsSlider = '#pickupNews'
const pickupNewsSwiper = new Swiper(pickupNewsSlider, {
  loop: true,
  loopedSlides: pickupNewsSlider.length,
  direction: 'vertical',
  autoplay: { delay: 5e3, disableOnInteraction: false },
  slidesPerView: 1,
  allowTouchMove: false,
  centeredSlides: true,
})
$(pickupNewsSlider).on('mouseover', function () {
  pickupNewsSwiper.autoplay.stop()
})

$(pickupNewsSlider).on('mouseout', function () {
  pickupNewsSwiper.autoplay.start()
})

$(function () {
  var t
  ;(t = '60%'),
    $('#concept').waypoint(
      function () {
        var e = new TimelineMax(),
          t = 0.7,
          a = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut },
          n = { opacity: 1, transform: 'translateY(0) translateX(0)', ease: Power3.easeInOut }
        e.add(TweenMax.to('#concept .l-conceptBg .conceptBg_img1', t, a)),
          e.add(TweenMax.to('#concept .l-conceptBg .conceptBg_img2', t, n), '-=0.5'),
          e.add(TweenMax.to('#concept .l-conceptBg .conceptBg_img3', t, n), '-=0.5'),
          e.add(TweenMax.to('#concept .l-conceptBg .conceptBg_img4', t, n), '-=0.5'),
          e.add(TweenMax.to('#concept .conceptTitleSub', t, a), '-=0.5'),
          e.add(
            TweenMax.staggerTo(
              ['#concept .conceptTitle-image-left', '#concept .conceptTitle-image-right'],
              t,
              a,
              0
            ),
            '-=0.5'
          ),
          e.add(TweenMax.to('#concept .conceptTitleMain', t, a), '-=0.5'),
          e.add(TweenMax.to('#concept .l-stripeBox', t, a), '-=0.5')
      },
      { offset: t }
    ),
    $('#reason').waypoint(
      function () {
        var e = new TimelineMax(),
          t = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut }
        e.add(TweenMax.to('#reason .contentTitle', 0.7, t)),
          e.add(TweenMax.to('#reason .contentTitle-sub', 0.7, t), '-=0.5'),
          e.add(TweenMax.staggerTo('#reason .l-reasonContent', 0.7, t, 0.2), '-=0.5')
      },
      { offset: t }
    ),
    $('#ai').waypoint(
      function () {
        var e = new TimelineMax(),
          t = { opacity: 1, transform: 'translateX(0)', ease: Power3.easeInOut },
          a = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut }
        e.add(TweenMax.to(['#ai #aiTitleSub', '#ai #aiTitle'], 0.7, t)),
          e.add(TweenMax.to('#ai .aiText', 0.7, a, 0.2)),
          e.add(TweenMax.to('#ai .l-moreBtn', 0.7, a, 0.2), '-=0.5')
      },
      { offset: t }
    ),
    $('#course').waypoint(
      function () {
        var e = new TimelineMax(),
          t = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut }
        e.add(TweenMax.to('#course .contentTitle', 0.7, t)),
          e.add(TweenMax.to('#course .contentTitle-sub', 0.7, t), '-=0.5'),
          $('#course .l-courseWrap .course').each(function () {
            e.add(TweenMax.to($(this), 0.7, t), '-=0.5')
          })
      },
      { offset: t }
    ),
    $('#mirai').waypoint(
      function () {
        var e = new TimelineMax(),
          t = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut }
        e.add(TweenMax.to('#mirai .mirai', 0.7, t)),
          e.add(TweenMax.to('#mirai .mirai-title', 0.7, t), '-=0.5'),
          e.add(TweenMax.to('#mirai .mirai-text', 0.7, t), '-=0.5')
      },
      { offset: t }
    ),
    $('#news').waypoint(
      function () {
        var e = new TimelineMax(),
          t = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut }
        e.add(TweenMax.to('#news .contentTitle', 0.7, t)),
          e.add(TweenMax.to('#news .contentTitle-sub', 0.7, t), '-=0.5'),
          e.add(TweenMax.staggerTo('#news .news-items .news-item', 0.7, t, 0.2), '-=0.5'),
          e.add(TweenMax.to('#news .l-moreBtn', 0.7, t)),
          e.add(TweenMax.to('#news .l-blogBnr', 0.7, t), '-=0.5')
      },
      { offset: t }
    ),
    $('#vr').waypoint(
      function () {
        var e = new TimelineMax(),
          t = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut }
        e.add(TweenMax.to('#vr .contentTitle', 0.7, t)),
          e.add(TweenMax.to('#vr .contentTitle-sub', 0.7, t), '-=0.5'),
          e.add(TweenMax.staggerTo('#vr .l-movie', 0.7, t), '-=0.5')
      },
      { offset: t }
    ),
    $('#parents').waypoint(
      function () {
        var e = new TimelineMax(),
          t = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut }
        e.add(TweenMax.to('#parents .contentTitle', 0.7, t)),
          e.add(TweenMax.to('#parents .contentTitle-sub', 0.7, t), '-=0.5'),
          e.add(TweenMax.staggerTo('#parents .parents-links .parents-link', 0.7, t, 0.2), '-=0.5')
      },
      { offset: t }
    ),
    $('.news-item-text').each(function () {
      var e = $(this).text(),
        t = e.slice(0, 40)
      40 < e.length ? $(this).text(t + '...') : $(this).text(t)
    })
}),
  $(window).on('load', function () {
    var e = new TimelineMax(),
      t = { opacity: 1, transform: 'translateY(0)', ease: Power3.easeInOut }
    e.add(TweenMax.to('#mv .bg', 0.7, t)),
      e.add(TweenMax.to('#mv .title', 0.7, t), '-=0.3'),
      e.add(TweenMax.to('#mv .titleSub', 0.7, t), '-=0.3')
  }),
  $(window).on('load resize', function () {
    var e, t
    ;(e = $('#mirai').width()),
      (t = 0),
      isSp()
        ? ((t = 0.64 * e), $('.l-mirai .mirai').css({ height: t + 'px' }))
        : e < 1440 && ((t = 0.2777777 * e), $('.l-mirai .mirai').css({ height: t + 'px' })),
      (function () {
        var e = $('#concept')
        if (isSp()) {
          var t = $('#concept .l-concept').height()
          e.css({ height: t - 40 + 'px' })
        } else e.css({ height: 'auto' })
      })(),
      (function () {
        if (isSp()) {
          var e = $('#ai'),
            t = 0.64 * e.width()
          e.css({ height: t + 'px' })
        }
      })()
  })
